<template>
  <div
    class="align-start text-left overflow-hidden transform transition-all w-full"
  >
    <div
      class="w-full bg-white border mb-4 border-gray-200 border-dashed rounded-md py-3 px-4"
    >
      <div>
        <span class="text-sm mr-2">paciente:</span>
        <span
          v-if="patient"
          class="text-md text-gray-700 font-medium capitalize"
          >{{ patient.names }} {{ patient.lastnames }}</span
        >
      </div>
      <div>
        <span class="text-sm mr-2">dirección de correo:</span>
        <span v-if="patient" class="text-md text-gray-700 font-medium">{{
          patient.email
        }}</span>
      </div>
      <div>
        <span class="text-sm mr-2">previsión de salud:</span>
        <span v-if="patient" class="text-md text-gray-700 font-medium">{{
          patient.patient.health
        }}</span>
      </div>
      <div>
        <span class="text-sm mr-2">rut:</span>
        <span v-if="patient" class="text-md text-gray-700 font-medium">{{
          patient.patient.rut
        }}</span>
      </div>
      <div>
        <span class="text-sm mr-2">Número de teléfono:</span>
        <span v-if="patient" class="text-md text-gray-700 font-medium">{{
          patient.patient.phone
        }}</span>
      </div>
    </div>
    <!-- notification -->
    <transition name="fade" mode="out-in">
      <div v-if="success" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  class="h-6 w-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  Paciente actualizado!
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  El paciente tiene nuevos datos de contacto.
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      success = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="error" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6 text-red-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ error }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      error = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- end notification -->
    <div
      class="bg-white w-full border border-gray-200 rounded-lg overflow-hidden"
    >
      <!-- title -->
      <div class="w-full flex flex-col justify-center items-start">
        <div class="w-full px-4 pt-4 sm:px-6 sm:pt-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Datos de contacto del paciente
          </h3>
          <p class="mt-1 text-left max-w-2xl text-sm text-gray-500">
            Edita los datos de contacto de este paciente.
          </p>
        </div>
        <form
          @submit.prevent="handleSubmit"
          class="inline-block align-middle transform transition-all w-full"
        >
          <div
            class="w-full grid grid-cols-6 gap-6 mt-4 px-4 pb-4 sm:px-6 sm:pb-6"
          >
            <!-- contact -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="contact"
                class="block text-sm font-medium text-gray-700"
                >Correo eletrónico de contacto</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.contact"
                  type="text"
                  name="contact"
                  id="contact"
                  :class="
                    submitted && $v.form.contact.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="xxxxx@xxxxx.xxx"
                />
              </div>
              <div
                v-if="submitted && !$v.form.contact.required"
                class="text-sm text-red-600"
              >
                La dirección de correo es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.contact.email"
                class="text-sm text-red-600"
              >
                La dirección de correo no es válida
              </div>
            </div>
            <!-- phone -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="phone" class="block text-sm font-medium text-gray-700"
                >Teléfono</label
              >
              <div class="mt-1 w-full flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  >{{ country.dialCode }}</span
                >
                <input
                  type="text"
                  v-model="form.phone"
                  name="phone"
                  id="phone"
                  :class="
                    submitted && $v.form.phone.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block w-full min-w-0 flex-1 rounded-none rounded-r-md px-3 py-2 sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.phone.required"
                class="text-sm text-red-600"
              >
                El teléfono es obligatorio
              </div>
            </div>
          </div>
          <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PatientService from '@/services/patientService';
import BlockService from '@/services/blockService';
import { required, email } from 'vuelidate/lib/validators';
import countries from '@/utils/country-codes.json';

export default {
  name: 'Datos',
  components: {},
  data() {
    return {
      countries: countries,
      countrySelector: false,
      country: {
        name: 'Chile',
        dialCode: '+56',
        isoCode: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
      },
      form: {
        contact: '',
        phone: '',
      },
      submitted: false,
      success: false,
      error: '',
      block: null,
      id: null,
      patient: null,
    };
  },
  validations: {
    form: {
      contact: {
        required,
        email,
      },
      phone: {
        required,
      },
    },
  },
  created() {
    this.updateData();
  },
  methods: {
    getPatient() {
      PatientService.getPatientFromDoctor(
        this.block.reservation.purchase.user_id
      )
        .then(response => {
          this.block.reservation.purchase.user = response.data.patient;
          this.patient = response.data.patient;
          this.id = this.patient.id;
          this.form.phone = this.block.reservation.purchase.user.patient.phone.replace(
            this.country.dialCode,
            ''
          );
          this.form.contact = this.block.reservation.purchase.user.patient.contact;
        })
        .catch(() => {
          this.$router.replace({ path: '/doctor/calendar' });
        });
    },
    updateData() {
      if (this.$route.query.block) {
        BlockService.getBlock(this.$route.query.block)
          .then(response => {
            this.block = response.data.block;
            this.getPatient();
          })
          .catch(() => {
            console.log('error getting block');
          });
      } else {
        this.block = {
          reservation: {
            purchase: {
              user_id: this.$route.params.user_id,
            },
          },
        };
        this.getPatient();
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      PatientService.setContactData({
        id: this.id.toString(),
        contact: this.form.contact,
        phone: this.form.phone,
      })
        .then(response => {
          this.form.phone = response.data.user.patient.phone.replace(
            this.country.dialCode,
            ''
          );
          this.form.contact = response.data.user.patient.contact;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
        })
        .catch(err => {
          console.log(`failed to create user: ${err}`);
          this.error = 'Hubo un error al actualizar los datos.';
          setTimeout(() => {
            this.error = '';
          }, 3000);
        });
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.updateData();
    },
    '$route.query.block'() {
      this.updateData();
    },
  },
};
</script>
